import { Button, Card, Popover, Position } from '@blueprintjs/core';
import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { withTranslation } from 'react-i18next';
import MenuLanguage from './i18n.component';

class Footer extends Component {
    render() {
        const {t} = this.props;
        return (
            <div style={{ position:'fixed', width:'100%', bottom:0, zIndex:10}}>
                <Column horizontal='stretch'>
                    <Card style={{ height:'20px' }}>
                        <Row horizontal='spaced' style={{marginTop:'-10px'}}>
                            <Column horizontal='start'>
                                {t("footer.text.copyright")} © 2021 The Wheaton Press Foundation.
                            </Column>
                            <Column horizontal='end' style={{marginTop:'-2px'}}>
                                <Popover content={<MenuLanguage/>} position={Position.TOP} fill={true}>
                                    <Button type='button' className='bp3-button bp3-small'>{t("footer.button.language")}</Button>
                                </Popover>
                            </Column>
                        </Row>
                    </Card>
                </Column>
            </div>
        );
    }
}

export default withTranslation()(Footer);