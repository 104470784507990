// Address routing for make it available global across frontend page file.
// please only enable one variable!

// production address
const ADDRESS = {
    api : "https://api.wheatonpress.com/",
    page : "https://flourishing.wheatonpress.com/",
}

// dev/test address
// const ADDRESS = {
//     api : "https://fa.kawanpena.com/",
//     page : "https://assessment.kawanpena.com/",
// }

// local development
// const ADDRESS = {
//     api : "https://api.wheatonpress.com/",
//     page : "http://localhost:3000/",
// }

export default ADDRESS;