import React from 'react';
import { Menu, MenuItem } from "@blueprintjs/core";
import { useTranslation } from "react-i18next"

const MenuLanguage = () => {
    const { i18n } = useTranslation();

    function setLanguage(targetLang){
        i18n.changeLanguage(targetLang)
    }

    return(
        <Menu>
            <MenuItem label="ID" text='Bahasa Indonesia' onClick={() => {setLanguage('id'); localStorage.setItem("language", "id")}} />
            <MenuItem label="EN-US" text='English (US)' onClick={() => {setLanguage('en'); localStorage.setItem("language", "en")}} />
        </Menu>
    )
}

export default MenuLanguage