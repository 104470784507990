import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import logo from './logo.svg';
import './App.css';
import { Helmet } from 'react-helmet';
import wheatonlogo from './assets/images/ref-icon.png';
import {isAuthenticated} from './authentication';
import Loadable from 'react-loadable';

import Navigation from './components/addons/navigation.component';
import Footer from './components/addons/footer.component';

const ProtectedRoute = Loadable({
  loader: () => import('./components/addons/protected.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Login = Loadable({
  loader: () => import('./components/addons/login.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const StudentLogin = Loadable({
  loader: () => import('./components/addons/studentlogin.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Signup = Loadable({
  loader: () => import('./components/addons/signup.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Forgot = Loadable({
  loader: () => import('./components/addons/forgot.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Reset = Loadable({
  loader: () => import('./components/addons/reset.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Home = Loadable({
  loader: () => import('./components/student/home.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Start = Loadable({
  loader: () => import('./components/student/start.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AssessmentPage = Loadable({
  loader: () => import('./components/student/assessment.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Finish = Loadable({
  loader: () => import('./components/student/finish.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const TeacherAccount = Loadable({
  loader: () => import('./components/teacher/account.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const Dashboard = Loadable({
  loader: () => import('./components/pic/pic-dash.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICAccount = Loadable({
  loader: () => import('./components/pic/pic-account.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICResponses = Loadable({
  loader: () => import('./components/pic/new-pic-responses.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICReports = Loadable({
  loader: () => import('./components/pic/new-pic-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICCompareResponse = Loadable({
  loader: () => import('./components/pic/new-pic-compare-responses.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICCompareReport = Loadable({
  loader: () => import('./components/pic/new-pic-compare-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})
// const PICImportCsv = Loadable({
//   loader: () => import('./components/pic/pic-import-csv.component'),
//   loading() {
//     return <div>Loading...</div>
//   },
// })
const PICFull = Loadable({
  loader: () => import('./components/pic/pic-full-report.component'),
  loading() {
    return <div>Loading...</div>
  },
})
// const Students = Loadable({
//   loader: () => import('./components/pic/pic-students.component'),
//   loading() {
//     return <div>Loading...</div>
//   },
// })
const PICTeacher = Loadable({
  loader: () => import('./components/pic/pic-teacher-account.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const PICEssay = Loadable({
  loader: () => import('./components/pic/pic-essay-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})

// Admin List
const AdminDashboard = Loadable({
  loader: () => import('./components/team/admindashboard.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminReports = Loadable({
  loader: () => import('./components/team/new-admin-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminResponses = Loadable({
  loader: () => import('./components/team/new-admin-responses.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminCompareReport = Loadable({
  loader: () => import('./components/team/new-admin-compare-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminCompareResponses = Loadable({
  loader: () => import('./components/team/new-admin-compare-responses.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminProfile = Loadable({
  loader: () => import('./components/team/admin-profile.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminFull = Loadable({
  loader: () => import('./components/team/admin-full-report.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminSegmentation = Loadable({
  loader: () => import('./components/team/admin-full-report-segmentation.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const GoogleSegmentation = Loadable({
  loader: () => import('./components/reports/admin-full-report-google.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const GoogleEduSegmentation = Loadable({
  loader: () => import('./components/reports/edu-full-report-google.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const GoogleEssaySegmentation = Loadable({
  loader: () => import('./components/reports/edu-full-report-essay.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminImportCsv = Loadable({
  loader: () => import('./components/team/admin-import-csv.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const SchoolAccount = Loadable({
  loader: () => import('./components/team/school-account.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminStudents = Loadable({
  loader: () => import('./components/team/admin-students.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminQuestionsAnswers = Loadable({
  loader: () => import('./components/team/questions-answers.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminEssay = Loadable({
  loader: () => import('./components/team/admin-essay-reports.component'),
  loading() {
    return <div>Loading...</div>
  },
})
const AdminTeacher = Loadable({
  loader: () => import('./components/team/new-admin-teacher-account.component'),
  loading() {
    return <div>Loading...</div>
  },
})


function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flourishing Assessments by Wheaton Press</title>
        <link rel='canonical' href='https://gsa.wheatonpress.com' />
        <link rel='icon' href={ wheatonlogo } type='image/x-icon' />
        <link rel='apple-touch' href={ wheatonlogo } type='image/x-icon' />
      </Helmet>
      <Router>
        {(isAuthenticated())?
        <Navigation/> : ''}
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/student/login" component={StudentLogin} />
          <Route exact path="/register" component={Signup} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/reset/:token" component={Reset} />
          <ProtectedRoute exact path="/student" component={Home}/>
          <ProtectedRoute path="/student/start-assessment" component={Start}/>
          <ProtectedRoute path="/student/assessment" component={AssessmentPage} />
          <ProtectedRoute path="/student/finish-assessment" component={Finish}/>
          {/* <ProtectedRoute path="/student/edit" component={StudentAccount}/> */}
          
          {/* start teacher or admin route */}
          <ProtectedRoute path="/account/teacher" component={TeacherAccount}/>
          <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
          <ProtectedRoute path="/account/school" component={PICAccount} />
          <ProtectedRoute path="/responses" component={PICResponses} />
          <ProtectedRoute path="/reports" component={PICReports} />
          <ProtectedRoute path="/compare/responses" component={PICCompareResponse} />
          <ProtectedRoute path="/compare/reports" component={PICCompareReport} />
          <ProtectedRoute path="/essay/" component={PICEssay} />
          {(isAuthenticated())? <Route path="/full/:school/:year/:batch" component={PICFull} />:<Redirect to='/login' />}
          {/* <ProtectedRoute path="/students" component={Students} /> */}
          {/* <ProtectedRoute path="/import" component={PICImportCsv} /> */}
          <ProtectedRoute path="/teachers" component={PICTeacher} />
          
          {/* start admin route */}
          <ProtectedRoute exact path="/admin" component={AdminDashboard} />
          <ProtectedRoute path="/admin/reports" component={AdminReports} />
          <ProtectedRoute path="/admin/responses" component={AdminResponses} />
          <ProtectedRoute path="/admin/teacher" component={AdminTeacher} />
          {(isAuthenticated())?<Route path="/admin/schoolaccount/:school" component={SchoolAccount} />:<Redirect to='/login' />}
          <ProtectedRoute path="/admin/compare/responses" component={AdminCompareResponses} />
          <ProtectedRoute path="/admin/compare/reports" component={AdminCompareReport} />
          <ProtectedRoute path="/admin/profile" component={AdminProfile} />
          <ProtectedRoute path="/admin/questions/" component={AdminQuestionsAnswers} />
          <ProtectedRoute path="/admin/essay/" component={AdminEssay} />
          <ProtectedRoute path="/admin/students/" component={AdminStudents} />
          {(isAuthenticated())? <Route path="/admin/full/:school/:year/:batch" component={AdminFull} />:<Redirect to='/login' />}
          {/* {(isAuthenticated())? <Route path="/admin/segmentation/:segmentation/:school/:year/:batch" component={AdminSegmentation} />:<Redirect to='/login' />} */}
          {(isAuthenticated())? <Route path="/google/:segmentation/:spreadsheet_id/:school/:language" component={GoogleSegmentation} />:<Redirect to='/login' />}
          {(isAuthenticated())? <Route path="/google-edu/:segmentation/:spreadsheet_id/:school/:language" component={GoogleEduSegmentation} />:<Redirect to='/login' />}
          {(isAuthenticated())? <Route path="/google-essay/:segmentation/:spreadsheet_id/:school/" component={GoogleEssaySegmentation} />:<Redirect to='/login' />}
          <ProtectedRoute path="/admin/import" component={AdminImportCsv} />
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
