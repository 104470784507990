import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Button, Popover, Switch, Toaster, Intent, Position, InputGroup, Icon, Dialog, H5, Classes, Card, Elevation, IconSize, H1, Divider, MenuDivider, H2,  } from '@blueprintjs/core';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { isAuthenticated } from '../../authentication';
// import { clear } from 'idb-keyval';
import Axios from 'axios';
import wheaton from '../../assets/images/fsa.png'
import wheaton2 from '../../assets/images/fea.png'
import { Row } from 'simple-flexbox';
import { Column } from 'simple-flexbox';
import { withTranslation } from 'react-i18next';
import ADDRESS from '../../api-address';

const buttonClick = val => {
    window.location = '/'+val;
}

const buttonClickNewWindow = val => {
    window.open(ADDRESS.page+val, '_blank');
    // window.open('http://localhost:3000/'+val, '_blank');
}

class Navigation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            role:0, isStudent: '', clicked: 0, values: '',
            isOpen: false, dialog_name:'', search_data: [], fileCounter: 0,
        }
        this.signOut = this.signOut.bind(this);
        this.onClickToHome = this.onClickToHome.bind(this);
        this.typeSwitches = this.typeSwitches.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.captureKey = this.captureKey.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.visualDialog = this.visualDialog.bind(this);
    }

    componentDidMount(){
        Axios.get(ADDRESS.api+'v1/me/', {headers: {'Authorization' : localStorage.getItem('X-Access-Type')+' '+localStorage.getItem('X-Access-Token')}})
        .then(response => {
            this.setState({
                role: response.data.role
            })
        })
        .catch(error => {
            localStorage.clear()
            window.location = '/login'
        })
    }

    onClickToHome(){
        if (this.state.role === 5) {
            window.location = '/student/start-assessment';
        } else if(this.state.role === 3) {
            window.location = '/dashboard';
        } else if (this.state.role === 1) {
            window.location = '/admin';
        }
    }
    
    typeSwitches(e){
        if (parseInt(localStorage.getItem('isStudent')) === 1) {
            this.setState({isStudent : 0})
            localStorage.setItem('isStudent', 0)
            window.scrollTo(0,0);
            window.location.reload();
            // this.showToast('Educator')
        } else {
            this.setState({isStudent : 1})
            localStorage.setItem('isStudent', 1)
            window.scrollTo(0,0);
            window.location.reload();
            // this.showToast('Student')
        }
    }

    onChangeSearch(e){
        this.setState({
            values: e.target.value,
        })
    }

    async captureKey(evt){
        var counter = 0
        if (evt.code === 'Enter') {
            if (this.state.values !== '') {
                const post = { school_name: this.state.values, is_student: parseInt(localStorage.getItem('isStudent')) }
                const searchSchool = await Axios.post(ADDRESS.api+'v1/google/search', post,  {headers: {'Authorization' : localStorage.getItem('X-Access-Type')+' '+localStorage.getItem('X-Access-Token')}})
                searchSchool.data.map((val, index) => {
                    val.phases.map((val1, index) => {
                        counter += val1.length
                    })
                })
                this.setState({
                    isOpen: true,
                    dialog_name: `'${this.state.values}' results`,
                    search_data: searchSchool.data,
                    fileCounter: counter,
                })
            }
        }
    }

    closeDialog(){
        this.setState({
            isOpen: false,
        })
    }

    showToast(msg){
        Toaster.create({
            className: 'bp3-success',
            position: Position.TOP,
            maxToasts: 1,
        }).show({ icon:"tick-circle", message: 'Switch to '+msg, intent:Intent.SUCCESS, timeout:5000, action:{onClick: () => window.location.reload(), text:'Reload'}});
    }

    signOut = (e) => {
        e.preventDefault();
        Axios.get(ADDRESS.api+'v1/logout', {headers: {'Authorization': localStorage.getItem('X-Access-Type')+' '+localStorage.getItem('X-Access-Token')}})
        .then( res => {
            if (res.data.status === 'logout') {
                localStorage.removeItem('X-Access-Token');
                localStorage.removeItem('X-Access-Expire');
                localStorage.removeItem('X-Access-Type');
                localStorage.removeItem('isStudent');
                if (this.state.role === 5) {
                    window.location = '/student/login'
                } else {
                    window.location = '/login'
                }
            }
        });
    }

    visualDialog(){
        return (
            <div className={Classes.DIALOG_BODY}>
                {this.state.search_data.length === 0 ?
                    <Row horizontal='center'>
                        <H2>No Result for {this.state.values}</H2>
                    </Row>
                :
                <div>
                    <Card style={{width:'100%', marginBottom:'10px'}} elevation={Elevation.THREE}>
                        <Row horizontal='spaced'  vertical='spaced'>
                            <H5>School Results</H5>
                        </Row>
                        {this.state.search_data.map((val, index) => 
                        <Card style={{width:'100%', marginBottom:'10px'}} interactive={true}>
                                <Row horizontal='spaced'>
                                    <Column horizontal='start'>
                                        <Row>
                                            <Column style={{marginRight:'12px'}}>
                                                <Icon icon="learning" size={32} />
                                            </Column>
                                            <Column>
                                                <p>{val.school_name}</p>
                                            </Column>
                                        </Row>
                                    </Column>
                                    <Column horizontal='end' style={{width:'400px'}}>
                                        <Row vertical='center'>
                                            <Button style={{marginRight:'10px'}} intent={Intent.PRIMARY} onClick={()=>buttonClick('admin/schoolaccount/'+val.id_school)} > <Icon icon="settings" size={16} style={{marginRight:'5px', marginLeft:'5px'}}/> Settings</Button>
                                            <Button intent={Intent.PRIMARY} onClick={(e) => {e.preventDefault(); localStorage.setItem('idSchool', val.id_school); buttonClick('dashboard')}}><Icon icon="key" size={16} style={{marginRight:'5px', marginLeft:'5px'}}/>Log In</Button>
                                        </Row>
                                    </Column>
                                </Row>
                        </Card>
                        )}
                    </Card>
                    <Divider style={{marginTop:'20px', marginBottom:'20px'}} title='Responses' />
                    {this.state.fileCounter === 0 ?
                        <Row horizontal='center'><H5>Nothing found on upload results</H5></Row> 
                        :
                        <Card style={{width:'100%', marginBottom:'10px'}} elevation={Elevation.THREE}>
                            <Row horizontal='spaced' vertical='spaced'>
                                <H5>Upload Results</H5>
                            </Row>
                            {this.state.search_data.map((val, index) => 
                                val.phases.map((val1, index) => 
                                    val1.map((val2, index) =>
                                        <Card style={{width:'100%', marginBottom:'10px'}} interactive={true}>
                                                <Row horizontal='spaced'>
                                                    <Column horizontal='start'>
                                                        <Row>
                                                            <Column style={{marginRight:'12px'}}>
                                                                <Icon icon="document" size={32} />
                                                            </Column>
                                                            <Column vertical='spaced'>
                                                                <Row>
                                                                    <p>{val2.out_var_1}/ {val2.education_phases === 'H' ? 'High School' : 'Middle School'}</p>
                                                                </Row>
                                                                <Row>
                                                                    <p>{val.school_name} on {val2.date}</p>
                                                                </Row>
                                                            </Column>
                                                        </Row>
                                                    </Column>
                                                    <Column horizontal='end'>
                                                        <Row>
                                                            {/* <Column style={{marginRight:'10px'}}>
                                                                <Button intent={Intent.DANGER} onClick={(e) => {e.preventDefault(); localStorage.setItem('idSchool', '0'); buttonClick('dashboard')}}><Icon icon="trash" size={16} /></Button>
                                                            </Column> */}
                                                            <Column>
                                                                <Button intent={Intent.SUCCESS} onClick={(e) => {e.preventDefault();  localStorage.getItem('isStudent') === "0" ? buttonClickNewWindow('google-edu/'+val2.education_phases+'/'+val2.spreadsheet_id+'/'+val.id_school) : buttonClickNewWindow('google/'+val2.education_phases+'/'+val2.spreadsheet_id+'/'+val.id_school)}}><Icon icon="download" size={16} /></Button>
                                                            </Column>
                                                        </Row>
                                                    </Column>
                                                </Row>
                                        </Card>
                                    )
                                )
                            )}
                        </Card>}
                </div>}
            </div>
        )
    }

    render(){
        const {t} = this.props;
        return(
            <Navbar fixedToTop='true'>
                <NavbarGroup>
                    <NavbarHeading><Link className="bp3-button bp3-minimal" onClick={ () => this.onClickToHome() } > <img src={parseInt(localStorage.getItem('isStudent')) === 0 && this.state.role !== 4 ? wheaton2 : wheaton} style={{ height:'32px' }} /> </Link></NavbarHeading>
                    {/* <Switch style={{marginTop:'10px'}} checked={this.state.isStudent} innerLabelChecked="S" innerLabel="E" labelElement={<strong><Icon icon="noto-v1:man-teacher" /> / <Icon icon="noto-v1:man-student" /></strong>} defaultChecked={true} large={true} onChange={this.typeSwitches} /> */}
                    {
                        (this.state.role === 3 || this.state.role === 1 || this.state.role === 6) ? 
                        <Switch style={{marginTop:'10px'}} checked={parseInt(localStorage.getItem('isStudent'))} innerLabelChecked="S" innerLabel="E" labelElement={parseInt(localStorage.getItem('isStudent')) === 1 ? t("navigation.label.switcher-student") : t("navigation.label.switcher-educator")} defaultChecked={true} large={true} onChange={this.typeSwitches} />
                        :
                        '' 
                    }
                </NavbarGroup>
                {(isAuthenticated()) ? 
                    <NavbarGroup className="bp3-align-right">
                        <Link className="bp3-button bp3-minimal" onClick={this.signOut}>{t("navigation.button.log-out")}</Link>
                    </NavbarGroup>
                :
                    ''}
                {
                    (this.state.role === 1) ?
                    <NavbarGroup className="bp3-align-right" style={{marginRight: '10px',width:'300px'}} >
                        {
                            this.state.role === 1 ? 
                            <InputGroup placeholder={t("navigation.placeholder.search")} fill={true} values={this.state.values} onChange={this.onChangeSearch} onKeyDown={(value) => this.captureKey(value)}></InputGroup>
                            :
                            ''
                        }
                    </NavbarGroup>
                    :
                    ''
                }
                <Dialog isOpen={this.state.isOpen} title={this.state.dialog_name} onClose={() => this.closeDialog()} style={{width:'900px'}}>
                    {this.visualDialog()}
                </Dialog>
            </Navbar>
        )
    }
}

export default withTranslation()(withRouter(Navigation));